<template>
  <div>
    <header class="fd-app-mode-ead-header">
      <div
        class="fd-app-mode-ead-header-bg"
        :style="{ backgroundImage: 'url(' + $store.state.fastPlataforma.banner_plataforma + ')', backgroundColor: '#000' }"
      />
      <div class="fd-app-mode-ead-header-overlay">
        <div class="container">
          <div class="row justify-content-between align-items-center">
            <div class="col-sm-12 col-md-12 col-lg-8">
              <h1 :style="{ color: $store.state.fastPersonalizacao.titulo_banner_color ? $store.state.fastPersonalizacao.titulo_banner_color : '#fff', textShadow: '1px 1px #000' }">
                {{ $store.state.fastPersonalizacao.titulo_banner ? $store.state.fastPersonalizacao.titulo_banner : '' }}
              </h1>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <mural-avisos />
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- fd-wrap -->
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb fast-plataforma-iuea-hide">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                :href="'/plataforma/' + $route.params.id_plataforma"
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
              >Modo aluno</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a href="#">Aulas Particulares</a>
            </li>
          </ul>
          <ul class="fd-app-breadcrumb d-none fast-menu-lives-aluno-iuea">
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
              >Menu principal</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Aulas Particulares</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-ead-content">
        <div class="container">
          <div class="row">
            <transition name="slide-fade">
              <component
                :is="HomeInternoAlunoMenuLateral"
                v-if="!isMobile()"
              />
            </transition>
            <div :class="isMobile() ? 'col-sm-12 col-md-12 col-lg-12' : 'col-sm-12 col-md-12 col-lg-11 fd-mode-ead-content-body'">
              <div class="fd-mode-ead-content mt-0 fast-iuea-ml-20">
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                          <!-- fd-app-welcome -->
                          <section class="fd-app-welcome text-left fast-plataforma-iuea-hide">
                            <h1 class="aluno_font_color">
                              Aulas <span>Particulares</span>
                            </h1>
                            <div class="fd-welcome-form-effect fast-plataforma-iuea-hide">
                              <img
                                :src="require('@/assets/images/form-effect-01.png')"
                                class="mt-2"
                              >
                            </div>
                          </section>
                          <!-- /fd-app-welcome -->
                          <section>
                            <!-- Conteúdo principal -->
                            <div class="row mt-4">
                              <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                                <button
                                  class="btn btn-sm btn-primary mt-2"
                                  @click="showModal('modalEscolherProfessor')"
                                >
                                  <small>Enviar Solicitação de aula</small>
                                </button>
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                                <h3>Aulas Solicitadas (3)</h3>
                              </div>
                              <div
                                v-for="index in 3"
                                :key="index"
                                class="col-sm-12 col-md-12 col-lg-12 mb-2"
                              >
                                <div class="card mb-2 ">
                                  <div class="card-header bg-ligth text-dark">
                                    <i class="fas fa-info-circle text-secondary mr-1" /> Solicitada em 11/01/2020 11:55
                                  </div>
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center justify-content-center mt-4 mt-md-0">
                                        <div
                                          class="mr-2"
                                          :style="{
                                            backgroundImage:
                                              'url(' +
                                              ajustaLinkImageUser('https://devapi.fastead.com/Files/img/273_professor_637474383000464518.png') +
                                              ')',
                                            width: '60px',
                                            height: '60px',
                                            borderRadius: '50%',
                                            backgroundSize: '100%',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: '50%',
                                          }"
                                        />Prof. Emerson Santos
                                      </div>
                                      <div class="col-sm-12 col-md-12 col-lg-6 d-flex align-items-center justify-content-center mt-4 mt-md-0">
                                        <div>
                                          <h6 class="mb-0">
                                            Disciplina
                                          </h6>
                                          <small>Desenvolvimento de sistemas</small>
                                        </div>                      
                                      </div>
                                      <div class="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center justify-content-center mt-4 mt-md-0">
                                        <div class="text-center">
                                          <button class="btn btn-sm btn-primary mb-2">
                                            <i class="fas fa-check-circle fa2-x" /> Detalhes
                                          </button>
                                        </div>                      
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>    
                            </div>
                            <div class="row mt-4">
                              <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                                <h3>Aulas realizadas (3)</h3>
                              </div>
                              <div
                                v-for="index in 3"
                                :key="index"
                                class="col-sm-12 col-md-12 col-lg-12 mb-2"
                              >
                                <div class="card mb-2 ">
                                  <div class="card-header bg-secondary text-white">
                                    Realizada em 11/01/2020 11:55
                                  </div>
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center justify-content-center mt-4 mt-md-0">
                                        <div
                                          class="mr-2"
                                          :style="{
                                            backgroundImage:
                                              'url(' +
                                              ajustaLinkImageUser('https://devapi.fastead.com/Files/img/273_professor_637474383000464518.png') +
                                              ')',
                                            width: '60px',
                                            height: '60px',
                                            borderRadius: '50%',
                                            backgroundSize: '100%',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: '50%',
                                          }"
                                        /> Prof. Emerson Santos
                                      </div>
                                      <div class="col-sm-12 col-md-12 col-lg-6 d-flex align-items-center justify-content-center mt-4 mt-md-0">
                                        <div>
                                          <h6 class="mb-0">
                                            Disciplina
                                          </h6>
                                          <small>Desenvolvimento de sistemas</small>
                                        </div>                      
                                      </div>
                                      <div class="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center justify-content-center mt-4 mt-md-0">
                                        <div class="text-center">
                                          <button class="btn btn-sm btn-secondary">
                                            <b-icon-arrow-counterclockwise /> Ver Feedback
                                          </button>
                                        </div>                      
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                                        <small>Diga-nos o que achou da resposta do professor:</small>
                                      </div>
                                    </div>
                                    <div class="row ">
                                      <div class="col-sm-12 col-md-12 col-lg-12 d-flex align-items-center justify-content-center">
                                        <star-rating
                                          :star-size="30"
                                          :show-rating="false"
                                          :max-rating="5"
                                        />
                                      </div>
                                    </div>  
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- /Conteúdo principal -->
                          </section>
                          <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                            <a
                              class="btn btn-red-hollow fast-iuea-ml-20"
                              :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
                            >
                              <b-icon-arrow-return-left /> Voltar
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /fd-wrap -->
    <modal
      name="modalEscolherProfessor"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4">
          <h4>Buscar professor</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEscolherProfessor')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 pb-2">
              <input
                type="text"
                class="form-control"
                placeholder="Filtrar por nome"
                @keyup="filtraProfessor"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 table-responsive">
              <table class="table table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col-sm-12 col-md-12 col-lg" />
                    <th scope="col-sm-12 col-md-12 col-lg">
                      Nome
                    </th>
                    <th scope="col-sm-12 col-md-12 col-lg">
                      Email
                    </th>
                    <th
                      class="text-center"
                      scope="col-sm-12 col-md-12 col-lg"
                    >
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody v-if="pageOfProfessores.length">
                  <tr
                    v-for="prof in pageOfProfessores"
                    :key="prof.id_professor"
                  >
                    <td scope="row">
                      <div
                        :style="{
                          backgroundImage:
                            'url(' + ajustaLinkImageUser(prof.image) + ')',
                          width: '60px',
                          height: '60px',
                          margin: 'auto',
                          marginBottom: '5px',
                          borderRadius: '50%',
                          backgroundSize: '100%',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: '50%',
                        }"
                      />
                    </td>
                    <td class="align-middle">
                      {{ prof.first_name + " " + prof.last_name }}
                    </td>
                    <td class="align-middle">
                      {{ prof.email }}
                    </td>
                    <td class="align-middle text-center">
                      <button
                        class="btn btn-sm btn-success btn-block"
                        title="Adicionar como professor desta aula"
                        @click.prevent="hideModal('modalEscolherProfessor'), showModal('exibeModalSolicitaAulaParticular')"
                      >
                        <small>
                          <b-icon-person-plus-fill /> Solicitar com o Professor
                        </small>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      class="text-center"
                      colspan="4"
                    >
                      Nenhum professor da plataforma disponível
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 text-center mt-4">
              <Pagination
                :page-size="5"
                :items="professoresFiltro"
                @changePage="pageOfProfessores = $event"
              />
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="exibeModalSolicitaAulaParticular"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-10 pl-4 pr-4">
          <h4>Descrição da Aula</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('exibeModalSolicitaAulaParticular')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="container">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="card">
                          <div class="card-body">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                              <h5>Descreva o assunto da aula.</h5>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12">
                              <textarea
                                class="form-control"
                                cols="30"
                                rows="2"
                              />
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12">
                              <h5>Opções de datas:</h5>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center">
                              <div class="pl-1 pr-1">
                                <small>1ª OPÇÃO</small>
                              </div>
                              <div class="pl-1 pr-1">
                                <input
                                  type="datetime-local"
                                  style="width:190px"
                                  class="form-control form-control-sm"
                                >
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center">
                              <div class="pl-1 pr-1">
                                <small>2ª OPÇÃO</small>
                              </div>
                              <div class="pl-1 pr-1">
                                <input
                                  type="datetime-local"
                                  style="width:190px"
                                  class="form-control form-control-sm"
                                >
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center">
                              <div class="pl-1 pr-1">
                                <small>3ª OPÇÃO</small>
                              </div>
                              <div class="pl-1 pr-1">
                                <input
                                  type="datetime-local"
                                  style="width:190px"
                                  class="form-control form-control-sm"
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button class="btn btn-sm btn-primary mt-2">
                        <small>Enviar Solicitação de aula</small>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
// Descomentar componente
import muralAvisos from "../components/MuralAvisos";
import Pagination from "../components/Pagination";
import StarRating from "vue-star-rating";
export default {
  // Nome do componente
  name: "HomeInternoAlunoAulasParticulares",
  // Componentes
  components: {
    muralAvisos,
    Pagination,
    StarRating,
  },
  // Carrega métodos globais
  mixins: [methods],
  // Variáveis locais
  data: function () {
    return {
       modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      fastTemplate: settings.fastTemplate,
      // Lista de professores
      professoresTotal: [],
      professoresFiltro: [],
      pageOfProfessores: [],
      
    };
  },
  // Carrega componente de forma assíncrona
  computed: {
    HomeInternoAlunoMenuLateral() {
      return () =>
        import(
          `@/templates/${this.fastTemplate}/components/HomeInternoAlunoMenuLateral.vue`
        );
    },
  },
  // Após carregar componente
  mounted: function () {
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma) {
      // Carrega dependências do aluno, valida plataforma, verifica se usuário está ativo, etc
      this.getFastDependecias(this.$route.params.id_plataforma, 'aluno')
        .then(() => {
            // Iniciar aqui
            this.$store.state.fastCarregando = false;
            this.getProfessoresPlataforma();
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  // Espaço reservado para os métodos
  methods: {
      //Buscar professores
      async getProfessoresPlataforma() {
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_plataforma_professor/lista?id_plataforma=" +
              this.$route.params.id_plataforma,
            this.fastAjaxOptions("GET")
          );
          let json = await resp.json();
          let objProfessores = Array.from(json);
          if (objProfessores.length > 0) {
            objProfessores.forEach((e, index) => {
              this.professoresTotal.forEach((p, index2) => {
                if (e.id_professor == p.id_professor) {
                  objProfessores.splice(index, 1);
                }
              });
            });
            this.professoresTotal = objProfessores;
            this.professoresFiltro = objProfessores;
          }
        } catch (e) {
          console.log("Erro", e);
        }
      },
      filtraProfessor(e) {
        let text = e.target.value;
        this.professoresFiltro = this.professoresTotal.filter(
          (e) =>
            e.first_name.toLowerCase().indexOf(text.toLowerCase()) != "-1" ||
            e.last_name.toLowerCase().indexOf(text.toLowerCase()) != "-1"
        );
      },
  },
};
</script>

<style scope>

</style>